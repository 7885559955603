import React, { Fragment } from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import "../scss/theme.scss";
import "../styles/font-awesome.min.css";
import "../styles/animate.min.css";

export default ({ data }) => (
  <Fragment>
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title="Coming Soon | Static Power Philippines Inc."
      link={[
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/img/icons/favicon-16x16.png"
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/img/icons/favicon-32x32.png"
        },
        {
          rel: "shortcut icon",
          type: "image/png",
          href: "/img/icons/favicon-96x96.png"
        }
      ]}
      meta={[
        {
          name: `description`,
          content:
            "Static Power | We provide all your Electrical equipments and services"
        },
        {
          property: `og:title`,
          content: "Coming Soon | Static Power Philippines Inc."
        },
        {
          property: `og:description`,
          content:
            "Static Power | We provide all your Electrical equipments and services"
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: "KDC Software Development Services"
        },
        {
          name: `twitter:title`,
          content: "Coming Soon | Static Power Philippines Inc."
        },
        {
          name: `twitter:description`,
          content:
            "Static Power | We provide all your Electrical equipments and services"
        }
      ].concat({
        name: `keywords`,
        content: [
          "electrical",
          "Philippines",
          "design",
          "build",
          "system",
          "supply",
          "troubleshoot",
          "repair",
          "equipments",
          "test",
          "manage",
          "bms"
        ].join(`, `)
      })}
    />
    <header id="header" className="u-header u-header--abs-top">
      <div className="u-header__section bg-transparent border-0">
        <div id="logoAndNav" className="container-fluid">
          <nav className="navbar navbar-expand justify-content-between u-header__navbar py-3">
            <div className="col-4 col-sm-6">
              <Link
                to="/coming-soon/"
                className="navbar-brand u-header__navbar-brand"
              >
                <span className="u-header__navbar-brand-default">
                  <Img
                    fixed={data.desktopLogo.childImageSharp.fixed}
                    alt="Static Power Philippines Inc."
                    title="Static Power Philippines Inc."
                  />
                </span>
                <span className="u-header__navbar-brand-mobile mt-2">
                  <Img
                    fixed={data.mobileLogo.childImageSharp.fixed}
                    alt="Static Power Philippines Inc."
                    title="Static Power Philippines Inc."
                  />
                </span>
              </Link>
            </div>

            <div className="col-8 col-sm-6 text-right">
              <OutboundLink
                className="btn btn-sm btn-primary"
                href="https://www.facebook.com/staticpowerph/"
                role="button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Follow Us for Updates
              </OutboundLink>
            </div>
          </nav>
        </div>
      </div>
    </header>

    <main id="content" role="main">
      <div className="bg-img-hero hero">
        <div className="d-sm-flex align-items-sm-center height-100vh--sm">
          <div className="container text-center space-3-top space-2-bottom space-3-bottom--lg">
            <div className="w-lg-60 text-center mx-lg-auto">
              <div className="mb-9">
                <h1 className="display-3 font-size-48--md-down font-weight-medium">
                  We're coming soon
                </h1>
                <p className="lead">
                  Our website is under construction. We'll be here soon with our
                  new awesome site,{" "}
                  <OutboundLink
                    href="https://www.facebook.com/staticpowerph/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    follow us on Facebook to be notified.
                  </OutboundLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <footer className="position-absolute-bottom-0--sm">
      <div className="container text-center space-1">
        <p className="small mb-0">
          &copy; Copyright 2019{" "}
          <a href="https://www.static.ph">Static Power Philippines</a>
          <br />
          <span className="small text-muted">
            Made by{" "}
            <OutboundLink
              href="https://www.kdcsoftware.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              KDC Software
            </OutboundLink>
          </span>
        </p>
      </div>
    </footer>
    <style jsx="true">{`
      .hero {
        background-image: url(${data.bgDesktopWide.childImageSharp.fluid.src});
      }
      @media (min-width: 992px) and (max-width: 1200px) {
        .hero {
          background-image: url(${data.bgDesktop.childImageSharp.fluid.src});
        }
      }
      @media (min-width: 768px) and (max-width: 992px) {
        .hero {
          background-image: url(${data.bgTablet.childImageSharp.fluid.src});
        }
      }
      @media (min-width: 576px) and (max-width: 768px) {
        .hero {
          background-image: url(${data.bgMobile.childImageSharp.fluid.src});
        }
      }
      @media (max-width: 576px) {
        .hero {
          background-image: url(${data.bgMobileXS.childImageSharp.fluid.src});
        }
      }
    `}</style>
  </Fragment>
);

export const query = graphql`
  query {
    desktopLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 200, height: 32) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    mobileLogo: file(relativePath: { eq: "logo-symbol.png" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    bgDesktopWide: file(relativePath: { eq: "coming-soon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "coming-soon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "coming-soon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "coming-soon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "coming-soon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
